


































import {Vue } from 'vue-property-decorator';
import ManualScheduledTemplate from '@/components/templates/ManualScheduledTemplate/ManualScheduledTemplate.vue';
import * as Config from '@/config';
import moment from 'moment';
import { SafieRelationGateDevice, TbEyeRelationGateDevice, Gate } from '@/types/alligate';
import { TbeyeLoginInfo } from '@/types/tbeye';
import { getAllGates } from '@/utils/alligate/gate';

const today = new Date();
const pre30days = new Date(new Date().setDate(today.getDate() - 30));
const dateStart = pre30days.toISOString().substr(0, 10);
const dateEnd = new Date().toISOString().substr(0, 10);
const TBEYE_LOGIN_INFO_COLUMN = 'tbeyeLoginInfos';

export interface DataType {
  actionItems: ActionType[];
  totalCount: number;
  data: any;
  logGates: any;
  logOwner: any;
  groups: any;
  loading: boolean;
  pageLogGate: number;
  maxRecord: number;
  safieRelationsGateDevice: SafieRelationGateDevice[];
  tbeyeRelationsGateDevice: TbEyeRelationGateDevice[];
  tbeyeLoginInfos: TbeyeLoginInfo[];
  gateFullList: Gate[];
  disableNextOwner: boolean;
  disableNextGate: boolean;
  pageSearch: number;
  maxRecordOwner: number;
  maxRecordGate: number;
  logType: string;
  notBefore: string;
  notAfter: string;
  worktimeTime: string;
  goBack: string;
  exportData: any;
  logsDataLoad: number;
  pageLog: number;
  errorMessageLogs: string;
  errorMessageLogsGate: string;
  dataUser: any;
  activeTab: number;
  isGetDataList: boolean;
  optionGate: any;
  optionOwner: any;
  arrayDataExportWorktimeDay: any;
  logsDataLoadOperation: number;
}
interface ActionType {
  title: string;
  tabname: string;
}
export default Vue.extend({
  name: 'ManuallyScheduledPage',
  components: {
    ManualScheduledTemplate,
  },
  mounted() {
    const dataQuery = this.$route.query;
    if (dataQuery.tabId !== undefined && dataQuery.tabId === 'user') {
      this.goBack = Config.USER_LIST;
    } else if (dataQuery.tabId !== undefined && dataQuery.tabId === 'gate') {
      this.goBack = Config.GATE;
    } else {
      this.goBack = '';
    }
    if (typeof localStorage.gateName === 'undefined') {
      localStorage.setItem('gateName', '');
    }
    if (typeof localStorage.userName === 'undefined') {
      localStorage.setItem('userName', '');
    }
    this.$emit('activeMenu', 'log');
    if (localStorage.isAdmin !== undefined && localStorage.isAdmin === 'false') {
      this.actionItems = [
        { title: 'ゲートログ', tabname: 'tab2' },
      ];
    }
    getAllGates(this).then((gates) => this.gateFullList = gates);
    this.getSafieRelationGateDevice(1);
    this.getTbeyeRelationGateDevice(1);
    this.getCacheTbeyeLoginInfo();
  },
  data: (): DataType => ({
    totalCount: 100,
    actionItems:  [
        // { title: '在室状況', tabname: 'tab1' },
        { title: 'ゲートログ', tabname: 'tab2' },
        { title: '操作ログ', tabname: 'tab3' },
    ],
    data: [],
    groups: [],
    safieRelationsGateDevice: [],
    tbeyeRelationsGateDevice: [],
    tbeyeLoginInfos: [],
    gateFullList: [],
    logGates: [],
    logOwner: [],
    loading: false,
    pageLogGate: 1,
    maxRecord: 0,
    disableNextOwner: false,
    disableNextGate: false,
    pageSearch: 1,
    maxRecordOwner: 0,
    maxRecordGate: 0,
    logType: '',
    notBefore: '',
    notAfter: '',
    exportData: [],
    logsDataLoad: 0,
    pageLog: 1,
    errorMessageLogs: '',
    errorMessageLogsGate: '',
    dataUser: [],
    activeTab: 0,
    isGetDataList: false,
    goBack: 'null',
    optionGate: [],
    optionOwner: [],
    arrayDataExportWorktimeDay: [],
    logsDataLoadOperation: 0,
    worktimeTime: '',
  }),
  methods: {
    getGateLogList(option: any, isSearch: boolean) {
      // this.loading = true;
      console.log('isSearch', isSearch);
      console.log('option', option);
      this.isGetDataList = true;
      this.disableNextGate = false;
      const notBefore = option.dateStart !== '' ? option.dateStart : dateStart + ' ' + '00:00';
      const notAfter = option.dateEnd !== '' ? option.dateEnd : dateEnd + ' ' + '23:59';
      let apiLink = '';
      this.optionGate = option;
      if (isSearch) {
        this.logGates = [];
      }
      apiLink = Config.LOG_GATE_LIST_API + '?limit=' + option.perPage + '&page=' + option.page +
          '&scorp=' + option.targets + '&keyword='  + option.text +
        '&logType=' + option.logType + '&notBefore=' + this.formatTimestamp(notBefore) +
        '&notAfter=' + this.formatTimestamp(notAfter);
      this.$http.get(apiLink)
        .then((response: any) => {
          // this.loading = false;
          this.maxRecordGate = response.body.maxRecords;
          this.errorMessageLogsGate = '';
          this.errorMessageLogs = '';
          if (response.body.maxRecords > 0) {
            response.body.logs.forEach((log: any) => {
              log.unixtimestamp = log.timestamp;
              log.timestamp = this.converToDateLog(log.timestamp);
              this.logGates.push(log);
            });
            this.maxRecord = response.body.maxRecords;
            if (response.body.maxRecords > response.body.endRecord) {
              this.disableNextGate = true;
            }
          } else {
            // this.logGates = [];
          }
          this.isGetDataList = false;
        })
        .then(null, (err) => {
          // this.loading = false;
          this.isGetDataList = false;
          switch (err.status) {
              case 400:
                this.errorMessageLogsGate = Config.ERROR_400;
                break;
              case 403:
                if (err.message === 'Unauthorized') {
                  this.errorMessageLogsGate = Config.ERROR_403;
                } else {
                  this.errorMessageLogsGate = Config.ERROR_403_DENIED;
                }
                break;
              case 404:
                this.errorMessageLogsGate = Config.ERROR_404;
                break;
              case 500:
                this.errorMessageLogsGate = Config.ERROR_500;
                break;
              case 503:
                this.errorMessageLogsGate = Config.ERROR_503;
                break;
              default:
                this.errorMessageLogsGate = Config.ERROR_DEFAULT;
                break;
            }
        });
    },
    converToDateLog(time: any) {
      return moment(new Date(time * 1000)).format('YYYY/MM/DD HH:mm:ss');
      // return moment(new Date(time * 1000)).locale('ja').format('ddd Do MMMM YYYY, HH:mm:ss');
    },
    converToDateLogExport(time: any) {
      if (time !== null && time !== '') {
        return moment(new Date(time * 1000)).format('YYYY/MM/DD HH:mm:ss');
      } else {
        return '';
      }
      // return moment(new Date(time * 1000)).locale('ja').format('ddd Do MMMM YYYY');
    },
    clickExportLog(data: any) {
      console.log(data);
      this.pageLog = 1;
      this.exportData = [];
      this.arrayDataExportWorktimeDay = [];
      this.logsDataLoad = 0;
      this.logType = data.typeExportCSV;
      this.notBefore = data.dateStartExportCSV;
      this.notAfter = data.dateEndExportCSV;
      this.worktimeTime = data.worktimeTime + ':00';
      this.loading = true;
      if (data.typeExportCSV !== '0') {
        this.getDataLog(1);
      } else {
        this.dataUser = [];
        this.getDataLogWorktimeDateStartDateEnd();
      }
    },
    clickExportOperationLog(data: any) {
      this.pageLog = 1;
      this.exportData = [];
      this.logsDataLoadOperation = 0;
      this.notBefore = data.dateStartExportCSV;
      this.notAfter = data.dateEndExportCSV;
      this.loading = true;
      this.dataUser = [];
      this.getDataExportOperationLog(1);
    },
    searchOperationLog(data: any) {
      const isSearch = true;
      this.getOperationLogList(data, isSearch);
    },
    searchGateLog(data: any) {
      const isSearch = true;
      console.log(data);
      this.getGateLogList(data, isSearch);
    },
    formatDateYYYYMMDD(date: any) {
      return date.getFullYear() +
      '-' +
      ('0' + (date.getMonth() + 1)).slice(-2) +
      '-' +
      ('0' + date.getDate()).slice(-2);
    },
    addDays(date: any, days: any) {
      const result = new Date(date);
      result.setDate(result.getDate() + days);
      return result;
    },
    async getDataLogWorktimeDateStartDateEnd() {
      const start = this.notBefore.split(' ')[0].split('-');
      const dateStartLogWorktime = new Date(Number(start[0]), Number(start[1]) - 1, Number(start[2]));
      const end = this.notAfter.split(' ')[0].split('-');
      const dateEndLogWorktime = new Date(Number(end[0]), Number(end[1]) - 1, Number(end[2]));
      const msDay = 24 * 60 * 60 * 1000;
      const arrayAPI = [];
      this.pageLog = Math.round(Math.abs((dateEndLogWorktime.getTime() - dateStartLogWorktime.getTime()) / msDay)) + 1;
      for (let i = 0; i < this.pageLog; i++) {
        const date = this.formatDateYYYYMMDD(this.addDays(dateStartLogWorktime, i));
        arrayAPI.push(Config.WORKTIME + '?date=' + date + '&time=' + this.worktimeTime);
        this.arrayDataExportWorktimeDay.push([]);
      }
      let indexArrayDay = 0;
      for (let i = 0; i < arrayAPI.length; i += 5) {
        const apiBatch = arrayAPI.slice(i, i + 5);
        const apiBatchIndex: any = [];
        apiBatch.forEach((item: any) => {
          apiBatchIndex.push({
            api: item,
            index: indexArrayDay++,
          });
        });
        await Promise.all(apiBatchIndex.map((api: any) => this.getWorkTime(api)));
      }
      this.arrayDataExportWorktimeDay.forEach((item: any): void => {
        item.forEach((itemValue: any): void => {
          this.exportData.push(itemValue);
        });
      });
      this.downloadCSV();
      this.reset();
    },
    getWorkTime(dataAPI: any) {
      return new Promise((resolve) => {
        this.$http.get(dataAPI.api)
          .then((response: any) => {
            if (response.body.worktime.length > 0) {
              response.body.worktime.forEach((item: any): void => {
                this.arrayDataExportWorktimeDay[dataAPI.index].push({
                  ['ユーザーID']: item.userId,
                  ['ユーザー名']: item.name,
                  ['ユーザー名ふりがな']: item.furigana,
                  ['メールアドレス']: item.email,
                  ['時間指定']: response.body.date ? response.body.date : '',
                  ['entry']: this.converToDateLogExport(item.entry),
                  ['exit']: this.converToDateLogExport(item.exit),
                });
              });
            } else {
              this.arrayDataExportWorktimeDay[dataAPI.index].push({
                ['ユーザーID']: '',
                ['ユーザー名']: '',
                ['ユーザー名ふりがな']: '',
                ['メールアドレス']: '',
                ['時間指定']: '',
                ['entry']: '',
                ['exit']: '',
              });
            }
            resolve(true);
          }, (err) => {
            this.arrayDataExportWorktimeDay[dataAPI.index].push({
              ['ユーザーID']: '',
              ['ユーザー名']: '',
              ['ユーザー名ふりがな']: '',
              ['メールアドレス']: '',
              ['時間指定']: '',
              ['entry']: '',
              ['exit']: '',
            });
            console.log(err);
            resolve(false);
          });
      });
    },
    getDataLogWorktime(page: any) {
      const apiLink = Config.WORKTIME;
      this.$http.get(apiLink)
        .then((response: any) => {
          if (response.body.worktime.length > 0) {
            response.body.worktime.forEach((item: any): void => {
              this.exportData.push({
                ['ユーザーID']: item.userId,
                ['ユーザー名']: item.name,
                ['ユーザー名ふりがな']: item.furigana,
                ['メールアドレス']: item.email,
                ['時間指定']: response.body.date ? response.body.date : '',
                ['entry']: this.converToDateLogExport(item.entry),
                ['exit']: this.converToDateLogExport(item.exit),
              });
            });
          } else {
            this.exportData.push({
              ['ユーザーID']: '',
              ['ユーザー名']: '',
              ['ユーザー名ふりがな']: '',
              ['メールアドレス']: '',
              ['時間指定']: '',
              ['entry']: '',
              ['exit']: '',
            });
          }
          this.logsDataLoad++;
        })
        .then(null, (err) => {
          this.exportData.push({
            ['ユーザーID']: '',
            ['ユーザー名']: '',
            ['ユーザー名ふりがな']: '',
            ['メールアドレス']: '',
            ['時間指定']: '',
            ['entry']: '',
            ['exit']: '',
          });
          console.log(err);
          this.logsDataLoad++;
        });
    },
    async getSafieRelationGateDevice(page: number): Promise<void> {
      return new Promise((resolve, reject) => {
        const limit = 25;
        const apiLink = `${Config.SAFIE_RELATIONS_GATE_API}?limit=${limit}`
          + `&page=${page}&sortKey=ASC:deviceId`;
        this.$http.get(apiLink)
        .then((response: any) => {
          if (response.body.relationsGateDevice) {
            const relationsGateDevice = response.body.relationsGateDevice;
            for (let i = 0; i < relationsGateDevice.length; i++) {
              this.$set(this.safieRelationsGateDevice, (response.body.startRecord - 1) + i, relationsGateDevice[i]);
            }
            if (response.body.endRecord !== response.body.maxRecords) {
              this.getSafieRelationGateDevice(page + 1).then(resolve).catch(resolve);
            } else {
              resolve();
            }
          }
        })
        .then( null, (err: any) => {
          reject(err);
        }); // catch
      });
    },
    getDataLog(page: any) {
      let key = '';
      if (this.optionGate.text !== '' && this.optionGate.text !== undefined && this.optionGate.text !== null) {
        key = '&scorp=' + this.optionGate.targets + '&keyword='  + this.optionGate.text;
      }
      const apiLink = Config.LOG_GATE_LIST_API + '?limit=1000&page=' + page + '&logType=' + this.logType +
      (key !== '' ? key : '' ) +
      (this.notBefore !== '' ? '&notBefore=' + this.formatTimestamp(this.notBefore) : '') +
        (this.notAfter !== '' ? '&notAfter=' + this.formatTimestamp(this.notAfter) : '');
      this.$http.get(apiLink)
        .then((response: any) => {
          if (response.body.maxRecords > 0) {
            this.pageLog = Math.ceil((response.body.maxRecords) / 1000);
            response.body.logs.forEach((log: any) => {
              this.exportData.push({
                '時間': this.converToDateLog(log.timestamp),
                'ユーザーID': log.userId,
                'ユーザー名': log.userName,
                'ユーザー名ふりがな': log.userFurigana,
                'ゲートID':  log.gateId,
                'ゲート名': log.gateName,
                'カード番号': log.deviceId,
                '照合機器タイプ': log.deviceType,
                'ログID': log.accessLogId,
                'ログ名称': log.logLabel,
                'ゲートログNo': log.sequenceNo,
                '電池電圧': log.voltage,
                '照合位置情報：緯度': '',
                '照合位置情報：経度': '',
                'メールアドレス': log.email,
                '備考１': log.option1,
                '備考2': log.option2,
              });
            });
            this.logsDataLoad++;
          } else {
            this.exportData.push({
                '時間指定': '',
                'ユーザーID': '',
                'ユーザー名': '',
                'ユーザー名ふりがな': '',
                'ゲートID': '',
                'ゲート名': '',
                'カード番号': '',
                '照合機器タイプ': '',
                'ログID': '',
                'ログ名称': '',
                'ゲートログNo': '',
                '電池電圧': '',
                '照合位置情報：緯度': '',
                '照合位置情報：経度': '',
                'メールアドレス': '',
                '備考１': '',
                '備考2': '',
              });
            this.logsDataLoad++;
          }
        })
        .then(null, (err) => {
          this.logsDataLoad++;
        });
    },
    getDataExportOperationLog(page: any) {
      let key = '';
      if (this.optionOwner.text !== '' && this.optionOwner.text !== undefined && this.optionOwner.text !== null) {
        key = '&scorp=' + this.optionOwner.targets + '&keyword='  + this.optionOwner.text;
      }
      const apiLink = Config.LOG_USER_OPERATION_LIST_API + '?limit=1000&page=' + page +
      (key !== '' ? key : '' ) +
      (this.notBefore !== '' ? '&notBefore=' + this.formatTimestamp(this.notBefore) : '') +
        (this.notAfter !== '' ? '&notAfter=' + this.formatTimestamp(this.notAfter) : '');
      this.$http.get(apiLink)
        .then((response: any) => {
          if (response.body.maxRecords > 0) {
            this.pageLog = Math.ceil((response.body.maxRecords) / 1000);
            response.body.logs.forEach((log: any) => {
              this.exportData.push({
                時間: this.converToDateLog(log.timestamp),
                ユーザーID: log.userId,
                ユーザー名: log.name,
                ふりがな: log.furigana,
                操作ログID:  log.configUserOperationLogId.toString(),
                メッセージ: log.logLabel,
                操作対象: log.targetId,
                操作内容: log.details,
              });
            });
            this.logsDataLoadOperation++;
          } else {
            this.exportData.push({
                時間: '',
                ユーザーID: '',
                ユーザー名: '',
                ふりがな: '',
                操作ログID:  '',
                メッセージ: '',
                操作対象: '',
                操作内容: '',
              });
            this.logsDataLoadOperation++;
          }
        })
        .then(null, (err) => {
          this.logsDataLoadOperation++;
        });
    },
    downloadCSV() {
      const saveAs = require('file-saver');
      let filename;
      const csv = this.convertArrayOfObjectsToCSV({
        data: this.exportData,
      });
      filename = 'logs-data.csv';
      const bom = new Uint8Array([0xEF, 0xBB, 0xBF]);
      const blob = new Blob([ bom, csv ], {type: 'text/csv;charset=utf-8;'});
      // ダウンロード実行
      const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
      if (isSafari) {
        saveAs(blob, filename);
      } else {
        console.log('window.navigator.msSaveOrOpenBlob', window.navigator.msSaveOrOpenBlob);
        if (window.navigator.msSaveOrOpenBlob !== undefined) {
          // IEの場合
          navigator.msSaveBlob(blob, filename);
        } else {
          // IE以外(Chrome, Firefox)
          // const downloadLink = $('<a></a>');
          const downloadLink =  document.createElement('a');
          downloadLink.setAttribute('href', window.URL.createObjectURL(blob));
          downloadLink.setAttribute('download', filename);
          downloadLink.setAttribute('target', '_blank');
          // $('body').append(downloadLink);
          downloadLink.click();
          downloadLink.remove();
        }
      }
    },
    convertArrayOfObjectsToCSV(args: any) {
      let result: any;
      let ctr: number;
      let keys: any[];
      let columnDelimiter: string|undefined;
      let lineDelimiter: any;
      let data: any;
      data = args.data || null;
      if (data == null || !data.length) {
        return null;
      }
      columnDelimiter = args.columnDelimiter || ',';
      lineDelimiter = args.lineDelimiter || '\n';
      keys = Object.keys(data[0]);
      result = '';
      result += keys.join(columnDelimiter);
      result += lineDelimiter;
      data.forEach((item: { [x: string]: any; }) => {
        ctr = 0;
        keys.forEach((key: any) => {
          if (ctr > 0) {
            result += columnDelimiter;
          }
          result += item[key];
          ctr++;
        });
        result += lineDelimiter;
      });
      return result;
    },
    getOperationLogList(option: any, isSearch: boolean) {
      console.log('AAAA');
      // this.loading = true;
      this.isGetDataList = true;
      this.disableNextOwner = false;
      const notBefore = option.dateStart !== '' ? option.dateStart : dateStart + ' ' + '00:00';
      const notAfter = option.dateEnd !== '' ? option.dateEnd : dateEnd + ' ' + '23:59';
      let apiLink = '';
      this.optionOwner = option;
      if (isSearch) {
        this.logOwner = [];
      }
      apiLink = Config.LOG_USER_OPERATION_LIST_API + '?limit=' + option.perPage + '&page=' + option.page +
      '&scorp=' + option.targets + '&keyword='  + option.text +
      '&notBefore=' + this.formatTimestamp(notBefore) + '&notAfter=' + this.formatTimestamp(notAfter);
      this.$http.get(apiLink)
        .then((response: any) => {
          // this.loading = false;
          this.maxRecordOwner = response.body.maxRecords;
          this.errorMessageLogsGate = '';
          this.errorMessageLogs = '';
          if (response.body.maxRecords > 0) {
            response.body.logs.forEach((log: any) => {
              log.timestamp = this.converToDateLog(log.timestamp);
              this.logOwner.push(log);
            });
            this.maxRecord = response.body.maxRecords;
            if (response.body.maxRecords > response.body.endRecord) {
              this.disableNextOwner = true;
            }
          } else {
            // this.logOwner = [];
          }
          this.isGetDataList = false;
        })
        .then(null, (err) => {
          // this.loading = false;
          this.isGetDataList = false;
          switch (err.status) {
              case 400:
                this.errorMessageLogs = Config.ERROR_400;
                break;
              case 403:
                if (err.message === 'Unauthorized') {
                  this.errorMessageLogs = Config.ERROR_403;
                } else {
                  this.errorMessageLogs = Config.ERROR_403_DENIED;
                }
                break;
              case 404:
                this.errorMessageLogs = Config.ERROR_404;
                break;
              case 500:
                this.errorMessageLogs = Config.ERROR_500;
                break;
              case 503:
                this.errorMessageLogs = Config.ERROR_503;
                break;
              default:
                this.errorMessageLogs = 'アクセス情報が不正です。ログインし直してからやり直してください。';
                break;
            }
        });
    },
    getTbeyeRelationGateDevice(page: number): Promise<void> {
      return new Promise((resolve, reject) => {
        const limit = 25;
        const apiLink = `${Config.TBEYE_RELATIONS_GATE_API}?limit=${limit}`
          + `&page=${page}&sortKey=ASC:gateId&isExceptOptionalGate=true`;

        this.$http.get(apiLink)
        .then((response: any) => {
          if (response.body.relationsGateDevice) {
            const relationsGateDevice = response.body.relationsGateDevice;
            for (let i = 0; i < relationsGateDevice.length; i++) {
              this.$set(this.tbeyeRelationsGateDevice, (response.body.startRecord - 1) + i, relationsGateDevice[i]);
            }
            if (response.body.endRecord !== response.body.maxRecords) {
              this.getTbeyeRelationGateDevice(page + 1).then(resolve).catch(resolve);
            } else {
              resolve();
            }
          } else {
            resolve();
          }
        })
        .then( null, (err: any) => {
          reject(err);
        }); // catch
      });
    },
    updateTbeyeLoginInfo(event: any) {
      const index = this.tbeyeLoginInfos.findIndex((logininfo) => logininfo.relationId === event.relationId);
      if (index === -1) {
        this.$set(this.tbeyeLoginInfos, this.tbeyeLoginInfos.length, event);
      } else {
        this.$set(this.tbeyeLoginInfos, index, event);
      }
      this.setCacheTbeyeLoginInfo();
    },
    async getCacheTbeyeLoginInfo() {
      const tbeyeLoginInfos = localStorage.getItem(TBEYE_LOGIN_INFO_COLUMN);
      if (tbeyeLoginInfos !== null) {
        this.tbeyeLoginInfos = JSON.parse(tbeyeLoginInfos);
      } else {
        this.tbeyeLoginInfos = [];
      }
    },
    async setCacheTbeyeLoginInfo() {
      localStorage.setItem(TBEYE_LOGIN_INFO_COLUMN, JSON.stringify(this.tbeyeLoginInfos));
    },
    formatTimestamp(t: string) {
      return t !== '' ? moment(t).unix() : '';
    },
    reset() {
      this.loading = false;
      this.exportData = [];
      this.errorMessageLogs = '';
    },
  },
  watch: {
    loading(value) {
      this.$emit('loading', value);
    },
    logsDataLoad(newVal, oldVal) {
      if (newVal > 0) {
        if (newVal >= this.pageLog) {
          console.log('newVal', newVal);
          console.log('pageLog', this.pageLog);
          this.downloadCSV();
          this.reset();
        } else {
          if (this.logType === '0') {
            //
          } else {
            this.getDataLog(newVal + 1);
          }
        }
      }
    },
    logsDataLoadOperation(newVal, oldVal) {
      if (newVal > 0) {
        if (newVal >= this.pageLog) {
          console.log('newVal', newVal);
          console.log('pageLog', this.pageLog);
          this.downloadCSV();
          this.reset();
        } else {
          this.getDataExportOperationLog(newVal + 1);
        }
      }
    },
  },
});
