var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Modal',{attrs:{"value":_vm.isVisible,"title":_vm.noticeSettingType.name},on:{"close":function($event){return _vm.$emit('close')}},scopedSlots:_vm._u([{key:"modal-body",fn:function(){return [_c('v-container',[_c('alert',{attrs:{"text":_vm.errorMessageLogsModal,"type":'error'},model:{value:(_vm.isDisplayErrorMessage),callback:function ($$v) {_vm.isDisplayErrorMessage=$$v},expression:"isDisplayErrorMessage"}}),_vm._v(" "),_c('alert',{attrs:{"text":_vm.successMessageLogsModal,"type":'success'},model:{value:(_vm.isDisplaySuccessMessage),callback:function ($$v) {_vm.isDisplaySuccessMessage=$$v},expression:"isDisplaySuccessMessage"}}),_vm._v(" "),_c('v-row',{attrs:{"justify":"end"}},[_c('v-col',{staticClass:"btn-add"},[_c('v-btn',{staticClass:"btn-primary",attrs:{"outlined":""},on:{"click":function($event){return _vm.$emit('click-open-add-notice-setting', _vm.noticeSettingType)}}},[_vm._v("\n          追加\n          ")])],1)],1),_vm._v(" "),_c('v-data-table',{staticClass:"border-table scroll-table",attrs:{"must-sort":"","headers":_vm.headers,"loading":_vm.isLoadingNoticeSetting,"items":_vm.selectedTypeNoticeSettings,"items-per-page":_vm.perPageItemLength,"footer-props":{'items-per-page-text': '表示件数:', 'items-per-page-options': [10, 50, 100]},"no-data-text":"データはありません","loading-text":"データを読み込中です","dense":"","item-key":"noticeSettingId","item-class":"align-center"},scopedSlots:_vm._u([{key:"item.isValid",fn:function(ref){
var item = ref.item;
return [_c('v-row',{attrs:{"justify":"center"}},[_c('input-form',{attrs:{"inputType":'switch'},on:{"change":function($event){return _vm.$emit('change-notice-setting-valid', item)}},model:{value:(item.isValid),callback:function ($$v) {_vm.$set(item, "isValid", $$v)},expression:"item.isValid"}})],1)]}},{key:"item.icons",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){return _vm.$emit('click-open-edit-notice-setting', item.noticeSettingId)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-square-edit-outline")])],1)]}}],null,true)},[_vm._v(" "),_c('span',[_vm._v(" 通知設定編集 ")])]),_vm._v(" "),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){return _vm.clickConfirmDeleteNoticeSetting(item.noticeSettingId)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-trash-can-outline")])],1)]}}],null,true)},[_vm._v(" "),_c('span',[_vm._v(" 通知設定削除 ")])])]}}],null,true)}),_vm._v(" "),_c('v-row',{staticClass:"mt-3",attrs:{"no-gutters":"","justify":"space-between"}},[_c('v-spacer')],1)],1),_vm._v(" "),_c('v-dialog',{attrs:{"width":"600","persistent":""},model:{value:(_vm.isDisplayConfirm),callback:function ($$v) {_vm.isDisplayConfirm=$$v},expression:"isDisplayConfirm"}},[_c('v-card',[_c('v-card-title',{staticClass:"background-color-green"},[_vm._v(" 通知設定 ")]),_vm._v(" "),_c('v-divider'),_vm._v(" "),_c('v-row',{staticStyle:{"height":"5px"},attrs:{"no-gutters":""}}),_vm._v(" "),_c('v-card-text',{staticClass:"pa-4"},[_c('p',[_vm._v("この通知設定を削除しますか？")])]),_vm._v(" "),_c('v-card-actions',[_c('v-row',{staticClass:"ma-2",attrs:{"justify":"end"}},[_c('v-btn',{staticClass:"btn-secondary",attrs:{"outlined":""},on:{"click":function($event){return _vm.deleteComfirmNoticeSetting(false)}}},[_vm._v("\n              キャンセル\n            ")]),_vm._v(" "),_c('v-btn',{staticClass:"btn-secondary",attrs:{"outlined":""},on:{"click":function($event){return _vm.deleteComfirmNoticeSetting(true)}}},[_vm._v("OK")])],1)],1)],1)],1)]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }