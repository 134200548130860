var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"allig-gate-passage-edit"},[_c('v-data-table',{attrs:{"must-sort":"","headers":_vm.headers,"items":_vm.innerValue,"items-per-page":_vm.innerValue.length,"item-key":"passageId","loading-text":"データを読み込中です","hide-default-footer":"","dense":""},scopedSlots:_vm._u([{key:"item.gateName",fn:function(ref){
var item = ref.item;
return [_c('v-autocomplete',{staticClass:"mt-4 pa-0",attrs:{"items":_vm.gateItems,"outlined":"","dense":""},on:{"change":function($event){return _vm.change(_vm.innerValue)}},model:{value:(item.gateId),callback:function ($$v) {_vm.$set(item, "gateId", $$v)},expression:"item.gateId"}})]}},{key:"item.time",fn:function(ref){
var item = ref.item;
return [_c('v-row',{attrs:{"justify":"center","align":"end"}},[_c('v-col',{staticClass:"mt-4 pa-0",attrs:{"cols":"3"}},[_c('v-menu',{attrs:{"content-class":"background-white","close-on-content-click":false,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"color":"green","readonly":"","outlined":"","dense":"","prepend-inner-icon":"mdi-calendar"},model:{value:(item.notBeforeFormattedDate),callback:function ($$v) {_vm.$set(item, "notBeforeFormattedDate", $$v)},expression:"item.notBeforeFormattedDate"}},'v-text-field',attrs,false),on))]}}],null,true),model:{value:(item.menuNotBeforeDate),callback:function ($$v) {_vm.$set(item, "menuNotBeforeDate", $$v)},expression:"item.menuNotBeforeDate"}},[_vm._v(" "),_c('v-date-picker',{attrs:{"color":"green","no-title":"","scrollable":""},on:{"input":function($event){item.notBeforeFormattedDate = _vm.formatDate(item.notBeforeDate); item.menuNotBeforeDate = false; _vm.change(_vm.innerValue)}},model:{value:(item.notBeforeDate),callback:function ($$v) {_vm.$set(item, "notBeforeDate", $$v)},expression:"item.notBeforeDate"}})],1)],1),_vm._v(" "),_c('v-col',{staticClass:"pa-0",attrs:{"cols":"3"}},[_c('v-menu',{attrs:{"content-class":"background-white","close-on-content-click":false,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"color":"green","outlined":"","dense":"","prepend-inner-icon":"mdi-clock-time-four-outline"},scopedSlots:_vm._u([{key:"append-outer",fn:function(){return [_c('div',{staticClass:"pr-2"},[_vm._v("\n                    ~\n                  ")])]},proxy:true}],null,true),model:{value:(item.notBeforeTime),callback:function ($$v) {_vm.$set(item, "notBeforeTime", $$v)},expression:"item.notBeforeTime"}},'v-text-field',attrs,false),on))]}}],null,true)},[_vm._v(" "),_c('v-row',{staticClass:"mt-7 mr-7 ml-7 mb-3 text-caption text-sm-caption text-md-caption text-lg-caption text-xl-caption"},[_c('div',{staticClass:"allig-select"},[_c('v-select',{attrs:{"items":_vm.hours,"color":"green","outlined":"","dense":""},on:{"change":function($event){item.notBeforeTime = _vm.formatTime(item.notBeforeTimeHour, item.notBeforeTimeMinute); _vm.change(_vm.innerValue)}},model:{value:(item.notBeforeTimeHour),callback:function ($$v) {_vm.$set(item, "notBeforeTimeHour", $$v)},expression:"item.notBeforeTimeHour"}})],1),_vm._v(" "),_c('div',{staticClass:"mt-2 mr-2 ml-2 font-weight-bold"},[_vm._v("\n                :\n              ")]),_vm._v(" "),_c('div',{staticClass:"allig-select"},[_c('v-select',{attrs:{"items":_vm.minutes,"color":"green","outlined":"","dense":"","hide-details":"","full-width":""},on:{"change":function($event){item.notBeforeTime = _vm.formatTime(item.notBeforeTimeHour, item.notBeforeTimeMinute);  _vm.change(_vm.innerValue)}},model:{value:(item.notBeforeTimeMinute),callback:function ($$v) {_vm.$set(item, "notBeforeTimeMinute", $$v)},expression:"item.notBeforeTimeMinute"}})],1)])],1)],1),_vm._v(" "),_c('v-col',{staticClass:"pa-0",attrs:{"cols":"3"}},[_c('v-menu',{attrs:{"content-class":"background-white","close-on-content-click":false,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({staticClass:"allig-text-field",attrs:{"color":"green","readonly":"","outlined":"","dense":"","prepend-inner-icon":"mdi-calendar"},model:{value:(item.notAfterFormattedDate),callback:function ($$v) {_vm.$set(item, "notAfterFormattedDate", $$v)},expression:"item.notAfterFormattedDate"}},'v-text-field',attrs,false),on))]}}],null,true),model:{value:(item.menuNotAfterDate),callback:function ($$v) {_vm.$set(item, "menuNotAfterDate", $$v)},expression:"item.menuNotAfterDate"}},[_vm._v(" "),_c('v-date-picker',{attrs:{"color":"green","no-title":"","scrollable":""},on:{"input":function($event){item.notAfterFormattedDate = _vm.formatDate(item.notAfterDate); item.menuNotAfterDate = false; _vm.change(_vm.innerValue)}},model:{value:(item.notAfterDate),callback:function ($$v) {_vm.$set(item, "notAfterDate", $$v)},expression:"item.notAfterDate"}})],1)],1),_vm._v(" "),_c('v-col',{staticClass:"pa-0",attrs:{"cols":"3"}},[_c('v-menu',{attrs:{"content-class":"background-white","close-on-content-click":false,"transition":"scale-transition","offset-y":"","left":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"color":"green","readonly":"","outlined":"","dense":"","prepend-inner-icon":"mdi-clock-time-four-outline"},scopedSlots:_vm._u([{key:"append-outer",fn:function(){return [_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.deleteItem(item.index)}}},[_c('v-icon',[_vm._v("mdi-close")])],1)]},proxy:true}],null,true),model:{value:(item.notAfterTime),callback:function ($$v) {_vm.$set(item, "notAfterTime", $$v)},expression:"item.notAfterTime"}},'v-text-field',attrs,false),on))]}}],null,true)},[_vm._v(" "),_c('v-row',{staticClass:"mt-7 mr-7 ml-7 mb-3 text-caption text-sm-caption text-md-caption text-lg-caption text-xl-caption"},[_c('div',{staticClass:"allig-select"},[_c('v-select',{attrs:{"items":_vm.hours,"color":"green","outlined":"","dense":""},on:{"change":function($event){item.notAfterTime = _vm.formatTime(item.notAfterTimeHour, item.notAfterTimeMinute); _vm.change(_vm.innerValue)}},model:{value:(item.notAfterTimeHour),callback:function ($$v) {_vm.$set(item, "notAfterTimeHour", $$v)},expression:"item.notAfterTimeHour"}})],1),_vm._v(" "),_c('div',{staticClass:"mt-2 mr-2 ml-2 font-weight-bold"},[_vm._v("\n                :\n              ")]),_vm._v(" "),_c('div',{staticClass:"allig-select"},[_c('v-select',{attrs:{"items":_vm.minutes,"color":"green","outlined":"","dense":"","hide-details":"","full-width":""},on:{"change":function($event){item.notAfterTime = _vm.formatTime(item.notAfterTimeHour, item.notAfterTimeMinute);  _vm.change(_vm.innerValue)}},model:{value:(item.notAfterTimeMinute),callback:function ($$v) {_vm.$set(item, "notAfterTimeMinute", $$v)},expression:"item.notAfterTimeMinute"}})],1)])],1)],1)],1)]}}],null,true)}),_vm._v(" "),_c('v-row',{staticClass:"ma-1",attrs:{"justify":"end"}},[_c('text-link',{attrs:{"text":"+ 行を追加する"},on:{"click":_vm.addItem}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }